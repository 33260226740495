import React from "react"
import TitleList from "../components/titlelist"
import { graphql } from "gatsby"

import { Helmet } from "react-helmet"







export default ({ data }) => {
  return (
    <div>

      <Helmet>
        <meta charSet="utf-8" />
        <title>Titles</title>
        <link rel="canonical" href="https://grmandco.com" />
      </Helmet>


      <h1 className="page_title">Books</h1>

      <TitleList titles={data.allWordpressWpTitles.edges} categories={data.allWordpressWpBookCategory.edges} ></TitleList>


    </div>
  )
}


//  //  allWordpressWpTitles(sort: {order: [ASC,DESC], fields: [ book_category, acf___publish_date]}) {

export const query = graphql`
  query {
    allWordpressWpPeople {
      edges {
        node {
          id
          name
          description
          slug
        }
      }
    }

    allWordpressWpTitles(sort: {order: DESC, fields: acf___publish_date}) {

      edges {
        node {
          id
          title
          slug
          book_category
          acf {
            publish_date
            attribution
            dimensions {
              width
              height
            }
            cover_image {
                id
                source_url
                media_details {
                  width
                  height
                  file
                  sizes {
                    tiny {
                      file
                      width
                      height
                      mime_type
                      source_url
                    }     
                    thumbnail {
                      file
                      width
                      height
                      mime_type
                      source_url
                    } 
                    medium {
                      file
                      width
                      height
                      mime_type
                      source_url
                    }
                    medium_large {
                      file
                      width
                      height
                      mime_type
                      source_url
                    }
                    large {
                      file
                      width
                      height
                      mime_type
                      source_url
                    }
                    full{
                      file
                      width
                      height
                    source_url
    
                    }
                    
                    
                  }
                }
              }
          }
        }
      }
    }
    allWordpressWpBookCategory {
        edges {
          node {
            id
            name
            wordpress_id
          }
        }
      }
  }
`
/* localFile {
  childImageSharp {
    fluid(
      base64Width: 1
      maxWidth: 1000,
      toFormat:PNG
      ) {
      aspectRatio
      # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
      ...GatsbyImageSharpFluid
    }
  }
}*/