//import stuff
import React, { Component } from 'react';
import Sticky from 'react-stickynode';
import { getBiggestDimension, isMobile } from "../components/utilities.js"
import BookItem from './bookitem.js';
import Autosuggest from 'react-autosuggest';
import { Link } from 'gatsby';




class TitleList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filterCategory: 0,
            filterText: "",
            categories: this.props.categories,
            titles: this.props.titles,
            bigSide: getBiggestDimension(this.props.titles),
            refs: this.makeRefs(),
            width: 0,
            height: 0,
            suggestions: []
        }

        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
        this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);


    }


    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    /////////////////////////////////

    getSuggestionValue(suggestion) {
        //console.log(suggestion.node.title);
        return "" //suggestion.node.title;//.node.title;   
    }

    getSuggestions(value) {
        const inputValue = value.value.trim().toLowerCase();
        const inputLength = inputValue.length;
        const suggestions = this.props.titles.filter(title =>
            title.node.title.toLowerCase().indexOf(inputValue) > -1
        ).slice(0,5)
        return inputLength === 0 ? [] : suggestions;
    };

    onSuggestionsFetchRequested(value) {

        this.setState({
            suggestions: this.getSuggestions(value)
        });
    };
    onSuggestionsClearRequested() {
        this.setState({
            suggestions: []
        });
    };

    renderSuggestion(suggestion) {
        return (<div>
            <Link to={"/titles/" + suggestion.node.slug}>
                {suggestion.node.title}
            </Link>
        </div>)
    }




    ////////////////////////////////

    setCategory(id, event) {
        this.setState(prevState => ({
            filterCategory: id,
            filterText: ''
        }));

        // this is inelegant but it will have to do?
        //window.scrollTo(window.scrollX, window.scrollY + 1);
    }



    setText(event) {
        let value = event.target.value
        this.setState(prevState => ({
            filterText: value,
            filterCategory: 0
        }));
        // this is inelegant but it will have to do?
        //window.scrollTo(window.scrollX, window.scrollY + 1);

    }

    // refactor this so selection is set on list once
    isSelected(node) {
        const filterCompare = this.state.filterText.toLowerCase().trim()
        const titleCompare = node.title.toLowerCase()
        const attrCompare = node.acf.attribution ? node.acf.attribution.toString().toLowerCase() : ""

        const selected = (node.book_category.length && node.book_category[0] === this.state.filterCategory)
            || (this.state.filterCategory === 0 && this.state.filterText.trim() === "")
            || (filterCompare !== "" && titleCompare.indexOf(filterCompare) > -1)
            || (filterCompare !== "" && attrCompare.indexOf(filterCompare) > -1)
            ? true : false

        return selected
    }

    makeRefs() {
        const items = this.props.titles
        let refs = []

        for (const item of items) {
            refs[item.node.id] = React.createRef()
        }

        //()
        return refs

    }



    componentDidUpdate(prevProps, prevState) {
        const that = this
        if (this.state.filterCategory !== prevState.filterCategory) {
            const firstOne = this.state.titles.find(function (e) {
                return that.isSelected(e.node) === true
            })
            const firstID = firstOne.node.id
            const theRef = this.state.refs[firstID]
        }
    }


    render() {




        return (
            // wrapper div of component
            <div className="title_list books">


                <div className="aux_content  title_categories">
                    <div className="pusher">
                        <Sticky top={130} bottomBoundary='#main_content' enabled={isMobile(this.state.width)}>



                            <div>

                                <Autosuggest
                                    suggestions={this.state.suggestions}
                                    renderSuggestion={this.renderSuggestion}
                                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                    onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                    inputProps={{
                                        placeholder: 'SEARCH',
                                        value: this.state.filterText,
                                        onChange: (e) => { this.setText(e) },
                                        className: (this.state.filterText.trim().length) ? 'full':'empty'
                                    }} />


                            </div>
                        </Sticky>
                    </div>
                </div>


                <div className=" main_content">




                    {this.state.titles.map(({ node }) => (
                        <BookItem
                            node={node}
                            isSelected={this.isSelected(node)}
                            refprop={this.state.refs[node.id]}
                            bigSide={this.state.bigSide}></BookItem>
                    ))}
                </div>

            </div>
        );
    }
}
// export the class so you can call it elsewhere
export default TitleList;
/*
<button
onClick={(e) => this.setCategory(0, e)} key={"cat_" + 0}
className={this.state.filterCategory === 0 && !this.state.filterText ? "active" : ""}
><span>ALL BOOKS</span></button>
{this.state.categories.map(({ node }) => (
<button
    onClick={(e) => this.setCategory(node.wordpress_id, e)} key={"cat_" + node.wordpress_id}
    className={this.state.filterCategory === node.wordpress_id ? "active" : ""}
><span>{node.name}</span></button>
))}
*/