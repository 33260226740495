import React from "react"
import { Link } from "gatsby"
import { unorphan } from "../components/utilities.js"
import LazyImage from "../components/lazyimage"
const BookItem = ({ node, isSelected, ref, bigSide }) => (
    <div
        className={"book " + (isSelected ? "selected" : "not")}
        key={node.id}
        ref={ref}
    ><Link to={"/titles/" + node.slug} className="booklink">
            <div className="coverWrap">
                <div className="cover" style={{
                    display: "block",
                    width: (90 * (node.acf.dimensions.width / bigSide)) + "%",
                    height: (90 * (node.acf.dimensions.height / bigSide)) + "%",

                }}>


                    <LazyImage image={node.acf.cover_image} alt="" sizes="(min-width: 768px) 50vw, (min-width: 1024px) 30vw,  100vw" ></LazyImage>
                    <span className="x"></span>
                </div>
            </div>
            <div className="book_title">
                <h4 dangerouslySetInnerHTML={{ __html: unorphan(node.title) }} />
            </div>
        </Link>
    </div>
)

export default BookItem